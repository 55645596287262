<template>
  <div>
    <v-container fluid class="ml-0 pl-0">
      <v-row  no-gutters>
        <v-col>
          Commission will be paid to your office via your preferred method. In the event of a cancellation, the refund check will be physically mailed to the insured and the return agency commission will be due from your agency back to Amwins.
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!paymentComplete" fluid class="ml-0 mb-0 pl-0">
      <v-row v-if="refinementProcessing" no-gutters>
        <v-col>
          <v-alert type="info" outlined class="text-left mb-0" dense :icon="false">
            Given the change in effective date, we are confirming your final premium.
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="refinementProcessing" no-gutters class="mt-4">
        <v-col text-center>
          <div class="text-center">
            <Loading :size="64" />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn :class="{ 'hasError': errors && errors.length }" color="secondary" large type="button"
                 @click="makePayment" :disabled="loading || disabled || refinementProcessing" key="save">
            <Loading v-if="loading" :size="24" class="mr-2" />
            Enter Payment Information
          </v-btn>
        </v-col>
        <ErrorMessages class="pt-2 text-bold" :errors="errors"></ErrorMessages>
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters class="mt-4">
        <v-col cols="9" sm="6" md="5" lg="4" xl="3">
          <b>Total Premium, Taxes, and Fees:</b>
        </v-col>
        <v-col cols="3" sm="6" md="7" lg="8" xl="9">
          {{ formatMoney(computedFeeInfo?.TotalPremium, true) }}
        </v-col>
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters align="center">
        <v-col cols="9" sm="6" md="5" lg="4" xl="3">
          <b>ACH Fee:</b>
        </v-col>
        <v-col cols="3" sm="6" md="7" lg="8" xl="9">
          {{ formatMoney(computedFeeInfo?.ACHFee, true) }}
        </v-col>
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters align="center">
        <v-col cols="9" sm="6" md="5" lg="4" xl="3">
          <b>CC Fee:</b>
        </v-col>
        <v-col cols="3" sm="6" md="7" lg="8" xl="9">
          {{ formatMoney(computedFeeInfo?.CCFee, true) }}
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="paymentComplete" fill-height fluid class="ml-0 pl-0 mb-0 pl-0">
      <v-row v-if="refinementProcessing" no-gutters>
        <v-col>
          <v-alert type="info" outlined class="text-left mb-0" dense :icon="false">
            Given the change in effective date, we are confirming your final premium.
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="refinementProcessing" no-gutters class="mt-4">
        <v-col text-center>
          <div class="text-center">
            <Loading :size="64" />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters>
        Payment Method
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters>
        <b>{{ formatMoney((paymentInfo.BillableTotal + paymentInfo.PayerFee), true) }}&nbsp;Total Premium</b>&nbsp;(Includes Taxes &amp; Fees {{formatMoney((paymentInfo.PayerFee), true)}})
      </v-row>
      <v-row v-if="achTransactionType && !refinementProcessing" no-gutters align="center" :key="'ach'">
        <v-icon v-if="achTransactionType" class="payment pr-2">fa-solid fa-university</v-icon>&nbsp;&nbsp;&nbsp;<b>ACH Payments</b>&nbsp;&nbsp;
        <v-btn color="secondary" small type="button" icon
               @click="makePayment" :disabled="loading || disabled" key="save" class="ml-2">
          <Loading v-if="loading" :size="24" class="mr-2" /><v-icon size="24" class="payment">fa-solid fa-pen-to-square</v-icon>
        </v-btn>
      </v-row>
      <v-row v-if="!achTransactionType && !refinementProcessing" no-gutters align="center" :key="'cc'">
        <v-icon class="payment">{{getPaymentIcon}}</v-icon>
        &nbsp;&nbsp;&nbsp;<b>Credit Card</b>&nbsp;&nbsp;
        <v-btn color="secondary" small type="button" icon
               @click="makePayment" :disabled="loading || disabled" key="save">
          <Loading v-if="loading" :size="24" class="mr-2" /><v-icon size="10" class="payment">fa-solid fa-pen-to-square</v-icon>
        </v-btn>
      </v-row>
      <v-row v-if="!refinementProcessing" no-gutters>
        <ErrorMessages class="pt-2 text-bold" :errors="errors"></ErrorMessages>
      </v-row>
    </v-container>


    <v-container fluid class="ml-0 pl-0">
      <v-row>
        <v-col>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>ePayPolicy operates this payment portal and may impose fees for processing payments via credit card or ACH debit. If a fee is applicable to this payment, it is reflected in the line item "Fee" above. Any such fee will be retained exclusively by ePayPolicy and neither Amwins nor any of its affiliates will receive any portion of, or profit, from any such fee. By clicking "Submit", I hereby (i) agree that my use of this payment portal is subject to ePayPolicy's terms and conditions, and (ii) authorize ePayPolicy to process payment by charging the credit card or debiting the ACH account, as applicable, in accordance with the payment method selected. By clicking "Submit", I authorize payment.</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { getTokenAsync } from '@/plugins/auth'
  import { formatMoney } from '@/scripts/helper';

  export default {
    name: 'Payment',
    props: {
      value: [String, Object],
      label: String,
      rules: String,
      errors: Array,
      disabled: Boolean,
      endorsement: Boolean
    },
    emits: [
      'change',
      'validate'
    ],
    data() {
      return {
        newValue: null,
        loading: false,
        paymentComplete: null,
        paymentInfo: null,
        transactionAmountWithFee: null,
        feeInfo: null,
        isLatestQuote: null
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          this.newValue = newVal;
        }
      },
      newValue() {
        this.emitValue();
      },
      '$store.state.additionalForm.selectedQuote.IsLatestQuote'(val) {
        if (val) {
          this.paymentInfo = null;
          this.feeInfo = null;
          this.newValue = null;
          this.paymentComplete = null;
          this.checkPayment();
        }
      },
      endorsement(val) {
        if (val) {
          this.paymentInfo = null;
          this.feeInfo = null;
          this.newValue = null;
          this.paymentComplete = null;
          this.checkPayment();
        }
      } 
    },
    computed: {
      getPaymentIcon() {
        switch (this.newValue.PaymentInfo.TransactionType.toLowerCase()) {
          case 'ach':
            return 'fa-solid fa-university'
          case 'mastercard':
            return 'fa-brands fa-cc-mastercard'
          case 'visa':
            return 'fa-brands fa-cc-visa'
          case 'discover':
            return 'fa-brands fa-cc-discover'
          case 'amex':
            return 'fa-brands fa-cc-amex'
          default:
            return 'fa-regular fa-credit-card'
        }
      },
      refinementProcessing() {
        if (this.endorsement) return false;

        return !this.$store.state.additionalForm.selectedQuote.IsLatestQuote;
      },
      achTransactionType() {
        return this.newValue.PaymentInfo.TransactionType.toLowerCase() === 'ach'
      },
      selectedQuote() {
        return this.$store.state.selectedQuote;
      },
      computedFeeInfo () {
        return this.feeInfo
      }
    },
    mounted() {
      this.checkPayment();
    },
    methods: {
      formatMoney,
      emitValue() {
        this.$emit('change', this.newValue)
        setTimeout(() => {
          this.$emit('validate');
        }, 100)
      },
      async checkPayment() {
        let requestUrl = ''
        if (this.endorsement) {
          requestUrl = this.createVersionedRoute('/api/Epay/endorsement') // endorsement loads versioned contracts
        } else {
          requestUrl = '/api/Epay?' // the epay get has no versioned contracts
        }
        let submissionId = this.endorsement ? this.$route.query.accountId :this.$route.query.id;
        const params = new URLSearchParams();
        params.append('submissionId', submissionId);
        const options = {
          headers: {
            "Authorization": "Bearer " + await getTokenAsync(),
          },
          handleError: false
        };
        const response = await this.$http.get(requestUrl + params.toString(), options).then(response => {
          this.handlePaymentCheckPresent(response.data);
        }).catch(error => {
            this.handlePopulateFees();
        });
      },
      handlePaymentCheckPresent(data) {
        this.paymentComplete = true;
        this.loading = false;
        this.paymentInfo = data;
        this.newValue = {
          PaymentComplete: true,
          PaymentInfo: this.paymentInfo,
          DateTime: new Date()
        }
      },
      createVersionedRoute(route) {
        if (this.$attrs.salesChannel === 'BindV2')
          return `${route}/v2?`;
        else
          return `${route}?`;
      },
      async handlePopulateFees() {
        this.loading = true
        const requestURL = this.createVersionedRoute(this.endorsement? '/api/Epay/feeInfo/Endorsement' : '/api/Epay/feeInfo');
        let submissionId = this.endorsement? this.$route.query.accountId : this.$route.query.id;
        const params = new URLSearchParams();
        params.append('submissionId', submissionId);
        const options = {
          headers: {
            "Authorization": "Bearer " + await getTokenAsync(),
          },
          handleError: false
        };
        const response = await this.$http.get(requestURL + params.toString(), options).then(response => {
          this.feeInfo = response.data;
        this.loading = false;
              }).catch(error => {
                setTimeout(() => {
                  this.handlePopulateFees()
                },2000);
        });
        
      },
      async makePayment() {
        this.loading = true;
        const requestURL = this.createVersionedRoute(this.endorsement ? '/api/Epay/url/endorsement' : '/api/Epay/url');
        const params = new URLSearchParams();
        params.append('submissionId',  this.endorsement? this.$route.query.accountId : this.$route.query.id);
        params.append('url', window.location.origin + '/epay' + (this.$attrs.salesChannel === 'BindV2' ? '/v2' : '/v1'));
        const options = {
          headers: {
            "Authorization": "Bearer " + await getTokenAsync(),
          },
          handleError: false
        }

        const response = await this.$http.get(requestURL + params.toString(), options);
        let height = window.innerHeight;
        let width = window.innerWidth;

        let top = Math.max((height - 1000) / 2, 0);
        let left = Math.max((width - 1000) / 2, 0);

        let windowHeight = Math.min(height, 1000);
        let windowWidth = Math.min(width, 1000);

        //The payment window is stored in a variable called 'w' so the method close() can be called upon it later
        let w = window.open(
          response.data,
          'payment',
          "menubar=1,location=no,resizable=1,width=" + windowWidth + ",height=" + windowHeight + ",top=" + top + ",left=" + left
        );

        w.focus();
        var wInterval = setInterval(() => {
          if (w.closed) {
            this.loading = true;
            clearInterval(wInterval);
            this.checkPayment();
            this.loading = false;
            return;
          }
        }, 1000);

      }


    }
  }
</script>

<style scoped lang="scss">
@import '@/style.scss';
  .payment {
    display: inline-block;
    vertical-align: middle;
    color: $amwins-blue;
    font-size: 64px !important;
  }
</style>