<template>
  <div>
    <PaymentV2 v-if="showPaymentV2" v-bind="$attrs" :endorsement="endorsement" v-on="$listeners" />
    <PaymentV1 v-else v-bind="$attrs" :endorsement="endorsement" v-on="$listeners" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PaymentV1 from '@/components/form/PaymentV1.vue';
import PaymentV2 from '@/components/form/PaymentV2.vue';

export default defineComponent({
  name: 'Payment',
  props: {
    endorsement: Boolean
  },
  components: {
    PaymentV1,
    PaymentV2
  },
  computed: {
    showPaymentV2() {
      return this.$store.getters.isFeatureFlagEnabled("ePayPaymentOptions");
    }
  }
});
</script>